var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Placeholder", {
    staticClass: "grid-no-results-placeholder",
    attrs: {
      title: _vm.title,
      subtitle: _vm.subtitle,
      orientation: _vm.horizontalOrientation,
      "bg-url": _vm.bgUrl
    },
    scopedSlots: _vm._u([
      {
        key: "action",
        fn: function() {
          return [
            _c("PlaceholderClearSearchButton", {
              attrs: { title: "REMOVE FILTERS" },
              nativeOn: {
                click: function($event) {
                  return _vm.onClearFilters.apply(null, arguments)
                }
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }